<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <div class="d-flex justify-content-between">
      <h5 class="font-weight-light text-uppercase mb-4">
        {{ $t("groupConfigurationPage.title") }}
      </h5>
      <div style="width: 180px">
        <router-link
          class="button-secondary"
          to="/notifications/group_configuration/create"
          ><!-- <i class="fas fa-plus"></i> -->
          {{ $t("groupConfigurationPage.createConfiguration") }}</router-link
        >
      </div>
    </div>

    <table class="table table-hover text-center">
      <thead>
        <tr>
          <th scope="col">{{ $t("groupConfigurationPage.group") }}</th>
          <th scope="col">{{ $t("country") }}</th>
          <th scope="col">{{ $t("status") }}</th>
          <th scope="col">{{ $t("groupConfigurationPage.menu") }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(config, index) in configs" :key="index">
          <td>
            <a :href="'/notifications/group_configuration/' + config.id">{{
              uppercaseFirstLetter(
                deleteSpecialCharacters(config.sales_channels_group_name)
              )
            }}</a>
          </td>

          <td>
            <div
              v-for="(country, index) in config.countries"
              :key="index"
              class="d-inline"
            >
              <span v-if="index != Object.keys(config.countries).length - 1">
                <b>{{ getCountryName(country) }}, </b>
              </span>
              <span v-else>
                <b>{{ getCountryName(country) }}</b>
              </span>
            </div>
          </td>
          <td>
            <template v-if="config.is_active">
              <span class="badge badge-success">{{
                $t("groupConfigurationPage.active")
              }}</span>
            </template>
            <template v-else>
              <span class="badge badge-danger">{{
                $t("groupConfigurationPage.inactive")
              }}</span>
            </template>
          </td>
          <td>
            {{
              uppercaseFirstLetter(deleteSpecialCharacters(config.menu_name))
            }}
          </td>
          <td>
            <a href="#" data-toggle="modal" data-target="#delete"
              ><i
                class="fas fa-times"
                v-on:click="indexToDelete = config.id"
              ></i
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="modal fade"
      id="delete"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteLabel">
              {{ $t("groupConfigurationPage.deleteConfiguration") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{
              $t("confirmDelete", {
                val: `this ${$t("groupConfigurationPage.configuration")}`,
              })
            }}?
          </div>
          <div class="modal-footer">
            <button type="button" class="button-light" data-dismiss="modal">
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="button-danger"
              data-dismiss="modal"
              v-on:click="deleteConfig()"
            >
              {{ $t("delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <SalesChannelsGroups class="pt-4 mt-4" />
  </div>
</template>

<script>
import SalesChannelsGroups from "../../components/notifications/rules-configuration/SalesChannelsGoups.vue";
import GroupConfigurationServices from "../../services/GroupsConfigurationService";
import CheckpointService from "../../services/CheckpointService";
import CountryService from "../../services/CountryService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const groupConfigurationService = new GroupConfigurationServices();
const checkpointService = new CheckpointService();
const countryService = new CountryService();

export default {
  name: "GroupsConfiguration",
  components: { SalesChannelsGroups, LoadingPage },
  data: () => {
    return {
      loaded: true,
      configs: "",
      checkpoints: "",
      seller_visibility: false,
      sales_channels_visibility: false,
      countries: [],
      indexToDelete: "",
    };
  },
  beforeMount() {
    groupConfigurationService.getConfigs().then((response) => {
      this.configs = response.data.data;
      checkpointService.getCheckpoints().then((response) => {
        this.checkpoints = response.data.data;
        countryService.getCountries().then((response) => {
          this.countries = response.data.data;
          this.loaded = false;
        });
      });
    });
  },
  methods: {
    deleteConfig() {
      groupConfigurationService
        .deleteConfiguration(this.indexToDelete)
        .then(() => {
          groupConfigurationService.getConfigs().then((response) => {
            this.configs = response.data.data;
          });
        });
    },

    getCountryName(country) {
      for (let i = 0; i < this.countries.length; i++) {
        if (country === this.countries[i].alpha2_code) {
          return this.countries[i].name;
        }
      }
      return country;
    },
    deleteSpecialCharacters(ch) {
      return ch.replace(/[^a-zA-Z ]/g, " ");
    },
    uppercaseFirstLetter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style lang="scss">
td {
  text-align: center;
}

#add-config {
  font-weight: 300;
  font-size: 14px;
  color: var(--secondary);
}

#add-config i {
  font-size: 14px;
}

#add-config:link {
  text-decoration: none;
}

#add-config:hover {
  text-decoration: none;
  font-weight: bold;
  color: var(--lightBlue);
}
</style>

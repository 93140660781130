<template>
  <div>
    <h5 class="font-weight-light text-uppercase mb-4">
      {{ $t("groupConfigurationPage.secondaryTitle") }}
    </h5>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            {{ $t("groupConfigurationPage.groups") }}
          </div>
          <div class="card-body">
            <table class="table table-striped" style="text-align: center">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("name") }}</th>
                  <th scope="col">{{ $t("salesChannel") }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(group, index) in salesChannelsGroups" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td
                    @click="
                      indexGroupToView = index;
                      copyGroup(index);
                    "
                    data-toggle="modal"
                    data-target="#modalViewGroup"
                  >
                    <a href="#">{{ group.name }}</a>
                  </td>
                  <td>
                    <div
                      v-for="(
                        sales, indexSalesChannels
                      ) in group.sales_channels"
                      :key="indexSalesChannels"
                      class="d-inline"
                      @click="
                        indexSalesChannelToView = indexSalesChannels;
                        copySalesChannel(index, indexSalesChannels);
                      "
                      data-toggle="modal"
                      data-target="#modalViewSalesChannel"
                    >
                      <span>
                        <!-- <a href="#"> --><b>{{ sales.name }}</b
                        ><!-- </a> -->
                      </span>
                    </div>
                  </td>
                  <td>
                    <a
                      href="#"
                      style="text-decoration: none"
                      class="fas fa-times"
                      data-toggle="modal"
                      data-target="#modalDeleteGroup"
                      @click="indexGroupToDelete = index"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              type="button"
              class="button-secondary"
              data-toggle="modal"
              @click="initializeNewGroup"
              data-target="#modalAddSalesChannelGroup"
            >
              {{ $t("groupConfigurationPage.addGroup") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalUpdateGroup"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalUpdateGroup"
      aria-hidden="true"
    >
      <div
        v-if="typeof indexGroupToUpdate === 'number'"
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalUpdateGroupTitle">
              {{ $t("groupConfigurationPage.updateGroup") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name">{{ $t("name") }}* </label>
                  <input
                    type="text"
                    class="form-control"
                    id="nameGroup"
                    placeholder="Name"
                    v-model="groupToUpdate.name"
                    :class="{ 'is-invalid': errorsGroups['name'] }"
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errorsGroups['name']"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="choosenSales">{{
                    $t("groupConfigurationPage.choosenSalesChannels")
                  }}</label>
                  <br />
                  <div
                    v-for="(
                      salesChannel, index
                    ) in groupToUpdate.sales_channels"
                    :key="index"
                    class="d-inline p-1"
                  >
                    <a
                      href="#"
                      class="badge badge-success shaking"
                      @click="removeSalesChannelFromGroupUpdate(index)"
                      >{{ salesChannel.name }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="nameSalesChannel">{{
                    $t("groupConfigurationPage.salesChannelsWithoutGroups")
                  }}</label>
                  <br />
                  <p
                    style="font-size: 8px"
                    v-if="!temporarySalesChannelsWithoutGroups.length"
                  >
                    {{
                      $t("groupConfigurationPage.noSalesChannelsWithoutGroups")
                    }}
                  </p>
                  <div
                    v-else
                    v-for="(
                      salesChannel, index
                    ) in temporarySalesChannelsWithoutGroups"
                    :key="index"
                    class="d-inline p-1"
                  >
                    <a
                      href="#"
                      class="badge badge-info"
                      @click="pushSalesChannelToGroupUpdate(index)"
                    >
                      {{ salesChannel.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="modalUpdateGroupClose"
              type="button"
              class="button-light"
              data-dismiss="modal"
              @click="reInitializaSalesChannelsWithoutGroups"
            >
              {{ $t("close") }}
            </button>
            <button type="button" class="button-primary" @click="groupUpdate">
              {{ $t("update") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modalViewGroup">
      <div
        v-if="typeof indexGroupToView === 'number'"
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalViewGroupTitle">
              {{ $t("groupConfigurationPage.groupInformation") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              id="modalViewGroupButton"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <b>{{ $t("name") }} </b>: {{ groupToUpdate.name }}
            <br />
            <b>{{ $t("salesChannels") }}</b
            >:
            <ul style="list-style: none">
              <li
                v-for="(salesChannel, index) in groupToUpdate.sales_channels"
                :key="index"
              >
                + {{ salesChannel.name }}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="button-light"
              data-dismiss="modal"
              @click="initializeNewGroup"
            >
              {{ $t("close") }}
            </button>
            <a
              @click="
                indexGroupToUpdate = indexGroupToView;
                closeModal('modalViewGroupButton');
              "
              href="#"
              class="button-primary float-right ml-3"
              data-toggle="modal"
              data-target="#modalUpdateGroup"
              data-dismiss="modal"
              >{{ $t("update") }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalAddSalesChannelGroup"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddSalesChannelGroup"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("groupConfigurationPage.addGroup") }}
            </h5>
            <button
              type="button"
              @click="initializeNewGroup"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="nameSalesChannelGroup">{{ $t("name") }}* </label>
                  <input
                    type="text"
                    class="form-control"
                    id="nameSalesChannel"
                    placeholder="Name"
                    v-model="newSalesChannelsGroup.name"
                    :class="{ 'is-invalid': errorsGroups['name'] }"
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errorsGroups['name']"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="nameSalesChannel">{{
                    $t("groupConfigurationPage.choosenSalesChannels")
                  }}</label>
                  <br />
                  <p
                    v-if="!choosenSalesChannelsForGroup.length"
                    style="font-size: 8px"
                  >
                    {{ $t("groupConfigurationPage.noChoosenSalesChannels") }}
                  </p>
                  <div
                    v-else
                    v-for="(
                      salesChannel, index
                    ) in choosenSalesChannelsForGroup"
                    :key="index"
                    class="d-inline p-1"
                  >
                    <a
                      href="#"
                      class="badge badge-success shaking"
                      @click="removeSalesChannelFromGroup(index)"
                    >
                      {{ salesChannel.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="nameSalesChannel">{{
                    $t("groupConfigurationPage.salesChannelsWithoutGroups")
                  }}</label>
                  <br />
                  <p
                    v-if="!salesChannelsWithoutGroup.length"
                    style="font-size: 8px"
                  >
                    {{
                      $t("groupConfigurationPage.noSalesChannelsWithoutGroups")
                    }}
                  </p>
                  <div
                    v-else
                    v-for="(salesChannel, index) in salesChannelsWithoutGroup"
                    :key="index"
                    class="d-inline p-1"
                  >
                    <a
                      href="#"
                      class="badge badge-info"
                      @click="pushSalesChannelToGroup(index)"
                    >
                      {{ salesChannel.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="modalAddSalesChannelGroupClose"
              type="button"
              class="button-light"
              data-dismiss="modal"
              @click="initializeNewGroup"
            >
              {{ $t("cancel") }}
            </button>
            <button type="button" class="button-primary" @click="addGroup">
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalDeleteGroup"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalDeleteGroup"
      aria-hidden="true"
    >
      <div
        v-if="typeof indexGroupToDelete == 'number'"
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("groupConfigurationPage.deleteGroup") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              id="modalDeleteGroupButton"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{
              $t("confirmDelete", {
                val: `this ${$t("groupConfigurationPage.group")}`,
              })
            }}?<br />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="button-light"
              data-dismiss="modal"
              @click="
                closeModal('modalDeleteGroupButton');
                stopIndex('indexGroupToDelete');
              "
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="button-danger"
              data-dismiss="modal"
              @click="groupDelete"
            >
              {{ $t("delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SalesChannelsManagementService from "../../../services/SalesChannelManagementService.js";
import NProgress from "nprogress";
//import $ from "jquery";

const salesChannelManagementService = new SalesChannelsManagementService();

export default {
  name: "SalesChannelsGroups",
  data: () => ({
    modalShow: true,
    salesChannels: [],
    salesChannelsGroups: [],
    salesChannelsWithoutGroup: [],
    choosenSalesChannelsForGroup: [],
    temporarySalesChannelsWithoutGroups: [],
    indexSalesChannelToView: null,
    indexGroupToView: null,
    indexGroupToDelete: null,
    indexGroupToUpdate: null,
    groupToUpdate: {},
    salesChannelToUpdate: {},
    newSalesChannelsGroup: {
      name: "",
      sales_channels: [],
    },
    errorsGroups: [],
  }),
  beforeMount() {
    salesChannelManagementService
      .getSalesChannels()
      .then((response) => {
        this.salesChannels = response.data.data;
        this.extractSalesChannelWithoutGroups();
      })
      .finally(() => this.$emit("loaded", true));

    salesChannelManagementService.getGroups().then((response) => {
      this.salesChannelsGroups = response.data.data;
    });
  },
  methods: {
    closeModal(closeButtonId) {
      closeButtonId.click();
    },
    stopIndex(indexName) {
      this[indexName] = null;
    },
    extractSalesChannelWithoutGroups() {
      let j = 0;
      this.salesChannelsWithoutGroup = [];
      for (let i = 0; i < this.salesChannels.length; i++) {
        if (this.salesChannels[i].sales_channels_group_id == null) {
          this.salesChannelsWithoutGroup[j] = JSON.parse(
            JSON.stringify(this.salesChannels[i])
          );
          j++;
        }
      }
    },
    addGroup() {
      let tempChoosenSales = JSON.parse(
        JSON.stringify(this.choosenSalesChannelsForGroup)
      );
      for (let i = 0; i < tempChoosenSales.length; i++) {
        tempChoosenSales[i] = JSON.parse(
          JSON.stringify(tempChoosenSales[i].id)
        );
      }
      this.newSalesChannelsGroup.sales_channels = JSON.parse(
        JSON.stringify(tempChoosenSales)
      );

      salesChannelManagementService
        .createGroup(this.newSalesChannelsGroup)
        .then(() => {
          document.getElementById("modalAddSalesChannelGroupClose").click();
          //$('#modalAddSalesChannelGroup').modal('hide');
          salesChannelManagementService.getSalesChannels().then((response) => {
            this.salesChannels = response.data.data;
            this.extractSalesChannelWithoutGroups();
          });
          salesChannelManagementService.getGroups().then((response) => {
            this.salesChannelsGroups = response.data.data;
          });
          this.newSalesChannelsGroup.name = "";
          this.newSalesChannelsGroup.sales_channels = [];

          this.choosenSalesChannelsForGroup = [];
          this.errorsGroups = [];
        })
        .catch(({ response }) => {
          if (
            response.data &&
            response.data.messages &&
            response.data.messages[0]
          ) {
            this.errorsGroups = response.data.messages[0];
          }
          NProgress.done();
        });
    },
    groupDelete() {
      salesChannelManagementService
        .deleteGroup(this.salesChannelsGroups[this.indexGroupToDelete])
        .then(() => {
          salesChannelManagementService.getSalesChannels().then((response) => {
            this.salesChannels = response.data.data;
            this.extractSalesChannelWithoutGroups();
          });
          salesChannelManagementService.getGroups().then((response) => {
            this.salesChannelsGroups = response.data.data;
          });
        })
        .catch(({ response }) => {
          if (
            response.data &&
            response.data.messages &&
            response.data.messages[0]
          ) {
            this.errorsGroups = response.data.messages[0];
          }
          this.$helpers.toast(
            this.errorsGroups[0] || "Group deletion failed",
            "danger"
          );
        });
    },
    groupUpdate() {
      let tempChoosenSales = JSON.parse(JSON.stringify(this.groupToUpdate));
      for (let i = 0; i < tempChoosenSales.sales_channels.length; i++) {
        tempChoosenSales.sales_channels[i] = JSON.parse(
          JSON.stringify(tempChoosenSales.sales_channels[i].id)
        );
      }

      salesChannelManagementService
        .updateGroup(tempChoosenSales)
        .then(() => {
          document.getElementById("modalUpdateGroupClose").click();
          //$('#modalUpdateGroup').modal('hide');
          this.errorsGroups = [];
          salesChannelManagementService.getSalesChannels().then((response) => {
            this.salesChannels = response.data.data;
            this.extractSalesChannelWithoutGroups();
          });
          salesChannelManagementService.getGroups().then((response) => {
            this.salesChannelsGroups = response.data.data;
          });
        })
        .catch(({ response }) => {
          if (
            response.data &&
            response.data.messages &&
            response.data.messages[0]
          ) {
            this.errorsGroups = response.data.messages[0];
          }
          NProgress.done();
        });
    },
    pushSalesChannelToGroup(channel) {
      this.choosenSalesChannelsForGroup.push(
        this.salesChannelsWithoutGroup[channel]
      );
      this.salesChannelsWithoutGroup.splice(channel, 1);
    },
    removeSalesChannelFromGroup(channel) {
      this.salesChannelsWithoutGroup.push(
        this.choosenSalesChannelsForGroup[channel]
      );
      this.choosenSalesChannelsForGroup.splice(channel, 1);
    },
    initializeNewGroup() {
      this.newSalesChannelsGroup.name = "";
      this.newSalesChannelsGroup.sales_channels = [];
      this.extractSalesChannelWithoutGroups();
      this.choosenSalesChannelsForGroup = [];
      this.errorsGroups = [];
    },
    reInitializaSalesChannelsWithoutGroups() {
      this.errorsGroups = [];
    },
    copySalesChannel(indexGroup, indexSales) {
      this.salesChannelToUpdate = JSON.parse(
        JSON.stringify(
          this.salesChannelsGroups[indexGroup].sales_channels[indexSales]
        )
      );
    },
    copyGroup(index) {
      this.groupToUpdate = JSON.parse(
        JSON.stringify(this.salesChannelsGroups[index])
      );
      this.temporarySalesChannelsWithoutGroups = JSON.parse(
        JSON.stringify(this.salesChannelsWithoutGroup)
      );
    },
    removeSalesChannelFromGroupUpdate(channel) {
      this.temporarySalesChannelsWithoutGroups.push(
        this.groupToUpdate.sales_channels[channel]
      );
      this.groupToUpdate.sales_channels.splice(channel, 1);
    },
    pushSalesChannelToGroupUpdate(channel) {
      this.groupToUpdate.sales_channels.push(
        this.temporarySalesChannelsWithoutGroups[channel]
      );
      this.temporarySalesChannelsWithoutGroups.splice(channel, 1);
    },
  },
};
</script>
